<template>
  <div class="customer-data">
    <div class="filter-section">
      <el-select v-model="selectedUser" placeholder="社交超级管理员等2人" class="filter-item">
        <el-option label="全部用户" value="all" />
      </el-select>
      
      <el-select v-model="selectedCustomer" placeholder="全部客户" class="filter-item">
        <el-option label="全部客户" value="all" />
      </el-select>

      <el-date-picker
        v-model="dateRange"
        type="daterange"
        range-separator="至"
        start-placeholder="2025-01-01"
        end-placeholder="2025-12-31"
        class="filter-item"
      />

      <el-select v-model="sortOrder" placeholder="签约顺序" class="filter-item">
        <el-option label="签约顺序" value="order" />
      </el-select>
    </div>

    <div class="table-section">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="customerName" label="客户名称" />
        <el-table-column prop="signAmount" label="签约额" sortable />
        <el-table-column prop="interestAmount" label="毛利额" sortable />
        <el-table-column prop="interestRate" label="毛利率" sortable />
        <el-table-column prop="successRate" label="成功率" sortable />
        <el-table-column prop="manager" label="客户跟进人" />
        <el-table-column prop="status" label="项目情况" />
        <el-table-column label="操作">
          <template #default>
            <el-button type="text">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="!tableData.length" class="empty-state">
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerData',
  data() {
    return {
      selectedUser: '',
      selectedCustomer: '',
      dateRange: [],
      sortOrder: '',
      tableData: []
    }
  }
}
</script>

<style scoped>
.customer-data {
  padding: 20px;
}

.filter-section {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.filter-item {
  min-width: 200px;
}

.empty-state {
  text-align: center;
  padding: 40px 0;
}

.empty-state img {
  width: 60px;
  margin-bottom: 10px;
}

.empty-state p {
  color: #999;
}
</style>